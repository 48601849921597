'use client';

import { usePathname } from 'next/navigation';
import { Link as MuiLink, Typography } from '@mui/material';
type Props = {
  href: string;
  label: string;
};
export const HeaderNavigationLink = ({
  href,
  label
}: Props) => {
  const pathname = usePathname();
  const isActive = pathname === href;
  return <MuiLink href={href} data-sentry-element="MuiLink" data-sentry-component="HeaderNavigationLink" data-sentry-source-file="HeaderNavigationLink.tsx">
      <Typography variant="h6" component="span" color={isActive ? 'primary.main' : 'text.primary'} noWrap data-sentry-element="Typography" data-sentry-source-file="HeaderNavigationLink.tsx">
        {label}
      </Typography>
    </MuiLink>;
};